import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import ListItemIcon from '../../images/ListItem.svg';

const CustomLi = ({ children, ...rest }) => {
  return (
    <Box
      as="li"
      direction="row"
      align="start"
      margin={{ vertical: 'medium' }}
      // key={index}
      {...rest}
    >
      <Box as="span" margin={{ right: '2rem' }} pad={{ top: '.5em' }}>
        <ListItemIcon />
      </Box>
      <Text color="black">{children}</Text>
    </Box>
  );
};

export default CustomLi;

CustomLi.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.node),
  ]),
};
