import React from 'react';
import PropTypes from 'prop-types';
import { Markdown as GrommetMarkdown } from 'grommet';
import CustomLi from './customLi';

const customCompontents = {
  li: {
    component: CustomLi,
    props: { pad: { left: '1em' } },
  },
};

const Markdown = ({ children }) => (
  <GrommetMarkdown components={customCompontents}>{children}</GrommetMarkdown>
);

export default Markdown;

Markdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
