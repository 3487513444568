import React from 'react';
import PropTypes from 'prop-types';
import SectionContainer from '../sections/SectionContainer';
import { Box, Text } from 'grommet';
import Button from '../../components/button';
import { navigate, graphql, useStaticQuery } from 'gatsby';

const PreFooter = props => {
  const data = useStaticQuery(graphql`
    query PreFooterQuery {
      prefooterJson {
        text
        buttonLabel
        buttonLink
      }
    }
  `);

  // let text, buttonLabel, buttonLink
  // if( typeof text === 'undefined'){

  // }

  // default to the json
  const text = props.text || data.prefooterJson.text;
  const buttonLabel = props.buttonLabel || data.prefooterJson.buttonLabel;
  const buttonLink = props.buttonLink || data.prefooterJson.buttonLink;

  // const { text, buttonLabel, buttonLink } = data.prefooterJson;

  return (
    <SectionContainer>
      <Box
        fill="horizontal"
        direction="row"
        align="center"
        pad={{ vertical: 'medium', horizontal: 'large' }}
        justify="around"
        background="light-2"
        wrap
      >
        <Box width="70%">
          <Text
            style={{ textTransform: 'uppercase' }}
            weight="bold"
            size="large"
          >
            {text}
          </Text>
        </Box>
        <Box flex="grow" align="center" margin={{ vertical: 'medium' }}>
          <Button label={buttonLabel} onClick={() => navigate(buttonLink)} />
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default PreFooter;

PreFooter.propTypes = {
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
};
