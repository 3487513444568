import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { ResponsiveContext, Box, Heading, Text, Grommet } from 'grommet';
import { deepFreeze, deepMerge } from 'grommet/utils';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import SectionContainer from '../components/sections/SectionContainer';
import { Instagram, Facebook, Strava } from '../components/linkedLogo';
import theme from 'constants/theme';
import PreFooter from '../components/prefooter/PreFooter';
import Markdown from '../components/markdown/markdown';

const BoxMaxWidth = styled(Box)`
  max-width: ${props => props.maxWidth};
`;

const custBreakPoints = {
  global: {
    breakpoints: {
      xsmall: {
        value: 660,
      },
      small: {
        value: 900,
      },
      medium: {
        value: 920,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '4px',
          large: '6px',
          xlarge: '12px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '6px',
          medium: '12px',
          large: '24px',
          xlarge: '48px',
        },
        size: {
          xxsmall: '24px',
          xsmall: '48px',
          small: '96px',
          medium: '192px',
          large: '384px',
          xlarge: '768px',
          full: '100%',
        },
      },
      large: {
        value: 1000,
      },
    },
  },
  paragraph: {
    medium: {
      maxWidth: '100%',
    },
  },
};

const bioPageTheme = deepFreeze(deepMerge({}, theme, custBreakPoints));

const renderBioList = list =>
  list.map((text, index) => (
    <Text color="white" size="medium" weight="bold" margin=".5rem" key={index}>
      {text}
    </Text>
  ));

const renderSocialList = () => (
  <Box direction="row" justify="center" align="center" pad="small">
    <Strava width="30px" height="30px" />
    <Instagram width="30px" height="30px" />
    <Facebook width="30px" height="30px" />
  </Box>
);

// eslint-disable-next-line react/prop-types
const ExtraSmallScreen = ({ heroText, imgFluid, bioShortList }) => (
  <Box align="center" pad={{ vertical: 'medium' }}>
    <Box background="accent-2" width="85vw" pad="large">
      <Heading>{heroText}</Heading>
    </Box>
    <Box fill="horizontal">
      <Img fluid={imgFluid} />
    </Box>
    <Box>
      <Box background="accent-2" width="85vw" pad="large">
        {renderBioList(bioShortList)}
      </Box>
      {renderSocialList()}
    </Box>
  </Box>
);

// eslint-disable-next-line react/prop-types
const NotExtraSmallScreen = ({ imgFluid, heroText, bioShortList }) => (
  <SectionContainer pad={{ vertical: 'medium', horizontal: 'large' }}>
    <BoxMaxWidth
      background="green"
      flex="grow"
      basis="53vw"
      // width="55vw"
      maxWidth="700px"
    >
      <Img fluid={imgFluid} />
    </BoxMaxWidth>
    <BoxMaxWidth maxWidth="430px">
      <Box
        background="accent-2"
        align="start"
        pad={{ horizontal: 'large', vertical: 'large' }}
      >
        <Heading>{heroText}</Heading>
        <Box pad={{ vertical: 'small' }}>{renderBioList(bioShortList)}</Box>
      </Box>
      {renderSocialList()}
    </BoxMaxWidth>
  </SectionContainer>
);

const ScreenProps = {
  imgFluid: PropTypes.object,
  heroText: PropTypes.string,
  bioShortList: PropTypes.arrayOf(PropTypes.string),
};

NotExtraSmallScreen.propTypes = ScreenProps;
ExtraSmallScreen.propTypes = ScreenProps;

const Bio = props => {
  const { heroImage, heroText, bioShortList, content } = props.data.bioJson;

  const userAgent =
    typeof window !== 'undefined' ? window.navigator.userAgent : 'Mobile';

  return (
    <Layout pageTitle="About Hayden">
      <Grommet theme={bioPageTheme} userAgent={userAgent}>
        <ResponsiveContext.Consumer>
          {size => (
            <>
              {size === 'xsmall' && (
                <ExtraSmallScreen
                  heroText={heroText}
                  imgFluid={heroImage.childImageSharp.fluid}
                  bioShortList={bioShortList}
                />
              )}
              {size !== 'xsmall' && (
                <NotExtraSmallScreen
                  heroText={heroText}
                  imgFluid={heroImage.childImageSharp.fluid}
                  bioShortList={bioShortList}
                />
              )}
            </>
          )}
        </ResponsiveContext.Consumer>
        <Box align="center" pad={{ horizontal: 'xlarge', vertical: 'xlarge' }}>
          <BoxMaxWidth maxWidth="800px">
            <Markdown>{content.childMarkdownRemark.rawMarkdownBody}</Markdown>
          </BoxMaxWidth>
        </Box>
      </Grommet>
      <PreFooter />
    </Layout>
  );
};

export default Bio;

Bio.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query BioPageQuery {
    bioJson {
      title
      heroImage {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroText
      bioShortList
      content {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;
